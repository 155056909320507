import React, { useEffect, useState, useCallback } from "react"
import smoothscroll from "smoothscroll-polyfill"
import { cn } from "../lib/helpers"
import styles from "./hero-image.module.css"

const HeroImage = ({
	preserveImage = true,
	height = "60vh",
	base64,
	aspectRatio,
	imageAlt,
	imageSrc,
	imageAlign = null,
	children,
	contentRef,
}) => {
	const [applyFade, setApplyFade] = useState(preserveImage)

	useEffect(() => {
		smoothscroll.polyfill()
	}, [])

	const scrollToContent = useCallback(() => {
		if (!contentRef.current) {
			return null
		}
		const header = document.querySelector("header")
		const headerOffset = header ? header.scrollHeight : 0
		const top =
			(contentRef.current ? contentRef.current.getBoundingClientRect().y + window.scrollY : window.innerHeight) -
			headerOffset
		window.scrollTo({
			top,
			left: 0,
			behavior: "smooth",
		})
	}, [contentRef])

	useEffect(() => {
		const checkForApplyFade = () => {
			setApplyFade(window.innerWidth / window.innerHeight < aspectRatio)
		}
		checkForApplyFade()
		window.addEventListener("resize", checkForApplyFade)
		return () => {
			window.removeEventListener("resize", checkForApplyFade)
		}
	}, [preserveImage, aspectRatio])

	return (
		<section className={styles.root} style={{ height }}>
			<div
				className={cn(
					styles.loader,
					height === "60vh" && styles.alignTop,
					preserveImage && applyFade && styles.withFade,
					!preserveImage && styles.loaderNoPreserve
				)}
				style={{
					backgroundImage: `url(${base64})`,
					height: preserveImage ? `${100 / aspectRatio}vw` : null,
					backgroundPosition: imageAlign,
				}}
			/>
			<div
				className={cn(
					styles.container,
					preserveImage && applyFade && styles.withFade,
					!preserveImage && styles.containerNoPreserve
				)}
			>
				<img
					alt={imageAlt}
					src={imageSrc}
					className={cn(styles.image, !preserveImage && styles.imageNoPreserve)}
					style={{
						objectPosition: imageAlign,
					}}
				/>
			</div>
			{typeof children === "function" && children({ scrollToContent })}
		</section>
	)
}

export default HeroImage

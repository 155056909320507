import { Field as FormikField, Form, Formik } from 'formik';
import React from 'react';
import Button from "../../components/button"
import { Control, Field, Help, Input, Label, Textarea } from 'react-bulma-components/lib/components/form';
import Icon from 'react-bulma-components/lib/components/icon';
import Message from 'react-bulma-components/lib/components/message';
import { FiMail, FiPhone, FiUser } from 'react-icons/fi';
import * as Yup from 'yup';
import styles from './contactForm.module.css';

const Schema = Yup.object().shape({
	name: Yup.string().required(`Please fill out your name.`),
	tel: Yup.string(),
	email: Yup.string().email(`Please enter a valid email.`).required(`Please do not forget your e-mail, we need this in order to get back in contact with you.`),
	message: Yup.string().required(`Please make sure you have filled out a message.`),
})

function ContactForm() {
	const encode = data => {
		return Object.keys(data)
			.map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
			.join('&')
	}
	async function handleSubmit(values, actions) {
		actions.setStatus(
			{
				color: 'dark', message: `Submitting form..`
			})
		try {
			await fetch("/", {
				method: "POST",
				headers: { "Content-Type": "application/x-www-form-urlencoded" },
				body: encode({ "form-name": "contact", ...values })
			})

			actions.setStatus({
				color: 'success', message: `Thanks for getting in touch! We'll get back to you as soon as we can.`
			})
		} catch (e) {
			alert(e)
			actions.setStatus({
				color: 'danger', message: `Our apologies.. it seems like something went wrong! Please check your network connection or try again in a few minutes.`
			})
		}
	}
	return (
		<Formik initialValues={{ "form-name": "contact", email: "", name: "", message: "" }} onSubmit={handleSubmit}
			validationSchema={Schema}>
			{({ values, errors, touched, dirty, status, isSubmitting, handleChange, handleBlur }) => (
				<div className={styles.root}>

				<Form name="contact" method="POST" data-netlify="true" netlify-honeypot="honeypot">
					<Field className={styles.field}>
						<Label htmlFor="name"><h2>Name</h2></Label>
						<Control iconLeft>
							<Input id="name" name="name" color={touched.name && !errors.name ? "success" : touched.name && errors.name ? "danger" : null} placeholder="Your Name" value={values.name} onChange={handleChange} onBlur={handleBlur}  />
							<Icon align="left">
								<FiUser />
							</Icon>
						</Control>
						{errors.name && touched.name && (
							<Help color="danger">{errors.name}</Help>
						)}
					</Field>

					<Field className={styles.field}>
						<Label htmlFor="email"><h2>Email address</h2></Label>
						<Control iconLeft>
							<Input id="email" name="email" value={values.email} color={touched.email && !errors.email ? "success" : touched.email && errors.email ? "danger" : null} type="email" placeholder="you@gmail.com" onChange={handleChange} onBlur={handleBlur} />
							<Icon align="left">
								<FiMail />
							</Icon>
						</Control>
						{errors.email && touched.email && (
							<Help color="danger">{errors.email}</Help>
						)}
					</Field>

					<Field className={styles.field}>
						<Label htmlFor="tel"><h2>Telephone number (optional)</h2></Label>
						<Control iconLeft>
							<Input id="tel" name="tel" value={values.tel} type="tel" placeholder="+31 6 12345678" onChange={handleChange} onBlur={handleBlur} />
							<Icon align="left">
								<FiPhone />
							</Icon>
						</Control>
						{errors.tel && touched.tel && (
							<Help color="danger">{errors.tel}</Help>
						)}
					</Field>

					<Field className={styles.field}>
						<Label htmlFor="message"><h2>Message</h2></Label>
						<Control>
							<Textarea id="message" name="message" color={touched.message && !errors.message ? "success" : touched.message && errors.message ? "danger" : null} placeholder="Enter your message" value={values.message} onChange={handleChange} onBlur={handleBlur} />
						</Control>
						{errors.message && touched.message && (
							<Help color="danger">{errors.message}</Help>
						)}
					</Field>

					<Field kind="group">
						<Control>
							<Button variant="accent" type="submit" disabled={status && status.color === 'dark'}>Submit</Button>
						</Control>
					</Field>

					<FormikField type="hidden" name="honeypot" />
					<FormikField type="hidden" name="form-name" />

					{status && status.color && status.message && (
						<Message color={status.color}>
							<Message.Body>{status.message}</Message.Body>
						</Message>
					)}
				</Form>
				</div>
			)}
		</Formik>
	)
}

export default ContactForm
import React from 'react'

import { cn } from '../lib/helpers'
import styles from './button.module.css'

const Button = ({ children, variant, size, className, ...rest }) => {
  return (
    <button className={cn(styles.root, styles[size], styles[variant], className)} {...rest}>
      {children}
    </button>
  )
}

export default Button

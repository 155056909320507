import React, { useRef, useCallback, useEffect, useState } from "react"
import Heading from "react-bulma-components/lib/components/heading"
import { FiChevronDown } from "react-icons/fi"

import styles from "./faq.module.css"
import BlockText from "../block-text"

function FAQ({ questions }) {
	return (
		<div className={styles.root}>
			{questions.map(({ question, answer }, key) => (
				<QuestionAnswer question={question} answer={answer} key={key} />
			))}
		</div>
	)
}

function useCollapsable(startAsOpen = false) {
	const [isOpen, setIsOpen] = useState(startAsOpen)
	const [height, setHeight] = useState(0)
	const ref = useRef(null)
	const initialHeight = useRef(isOpen ? `max-content` : `0px`)

	const toggleCollapsable = useCallback(() => {
		setIsOpen(!isOpen)
	}, [setIsOpen, isOpen])

	useEffect(() => {
		function setHeightCallback() {
			const { current } = ref
			if (!current || current.scrollHeight === 0) {
				return requestAnimationFrame(setHeightCallback)
			}
			initialHeight.current = null
			setHeight(current.scrollHeight)
		}
		requestAnimationFrame(setHeightCallback)
		window.addEventListener("resize", setHeightCallback)
		return () => window.removeEventListener("resize", setHeightCallback)
	}, [])

	const maxHeight = isOpen
		? // use initial height if it is open
		  // since this is a really large number to help
		  // when the accordions starts as open but
		  // we do not know the height yet
		  initialHeight.current || `${height}px`
		: `0px`

	return { toggleCollapsable, isOpen, maxHeight, ref }
}

function QuestionAnswer({ question, answer }) {
	const { toggleCollapsable, isOpen, maxHeight, ref } = useCollapsable(false)

	return (
		<div
			className={styles.questionAnswer}
			onClick={toggleCollapsable}
			onKeyDown={toggleCollapsable}
			role="button"
			tabIndex="0"
		>
			<Heading size={4} renderAs="h2" className={styles.question}>
				{question}
				<FiChevronDown
					className={styles.icon}
					style={{
						transform: `rotateX(${isOpen ? "180deg" : "0deg"})`,
					}}
				/>
			</Heading>
			<div
				ref={ref}
				className={styles.animateWrapper}
				style={{
					maxHeight,
				}}
			>
				<div className={styles.answer}>
					<BlockText blocks={answer} />
				</div>
			</div>
		</div>
	)
}

export default FAQ

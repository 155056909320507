import BaseBlockContent from "@sanity/block-content-to-react"
import React from "react"
import FAQ from "./faq"
import ContactForm from "./contactForm"
import Map from "./map"
import styles from "./index.module.css"
import Heading from "react-bulma-components/lib/components/heading"

const serializers = {
	types: {
		block(props) {
			switch (props.node.style) {
				case "h1":
					return (
						<Heading size={1} renderAs="h1" className={styles.h1}>
							{props.children}
						</Heading>
					)

				case "h2":
					return (
						<Heading size={2} renderAs="h2" className={styles.h2}>
							{props.children}
						</Heading>
					)

				case "h3":
					return (
						<Heading size={3} renderAs="h3" className={styles.h3}>
							{props.children}
						</Heading>
					)

				case "h4":
					return (
						<Heading size={4} renderAs="h4" className={styles.h4}>
							{props.children}
						</Heading>
					)

				case "blockquote":
					return <blockquote className={styles.content}>{props.children}</blockquote>

				case "ul":
					return <ul className={styles.content}>{props.children}</ul>

				case "li":
					return <li className={styles.content}>{props.children}</li>

				default:
					return <p className={styles.paragraph}>{props.children}</p>
			}
		},
		faq(props) {
			return <FAQ {...props.node} />
		},
		contactForm(props) {
			return <ContactForm {...props.node} />
		},
		map(props) {
			return <Map {...props.node} />
		},
	},
}

const BlockContent = ({ blocks }) => <BaseBlockContent blocks={blocks} serializers={serializers} />

export default BlockContent

import React from "react"
import styles from "./map.module.css"

function Map({ address, zoom = 10 }) {
	return (
		<div className={styles.root}>
			<iframe
				className={styles.map}
				width="600"
				height="500"
				title={address}
				src={`https://maps.google.com/maps?q=${address}&zoom=${zoom}&t=&z=13&ie=UTF8&iwloc=&output=embed`}
				frameBorder="0"
				scrolling="no"
				marginHeight="0"
				marginWidth="0"
			/>
		</div>
	)
}

export default Map
